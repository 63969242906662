<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-maintenance id="edit-maintenance" :maintenance="selectedMaintenance" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button class="ml-2" variant="primary" :to="{name: 'maintenance.create'}" v-if="can('Maintenances/Create')"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill d-flex flex-row dayview-width py-3 gap-2" v-if="can('Maintenances/Create')">
      <b-card
        v-for="(column, key) in data"
        :key="key"
        class="ml-1 mr-1"
        :style="{
                 'width': '350px',
                 'min-width': '350px',
                 'overflow': 'hidden'
            }"
      >
        <h4>{{ column.name }}</h4>

        <draggable
          v-bind="dragOptions"
          v-model="column.maintenances"
          group="maintenance"
          draggable=".item"
          class="d-flex flex-column gap-2"
          style="height: 100%; overflow-y: auto; padding-bottom: 20px;"
          @change="onChange($event, column)"
        >
          <template v-for="maintenance in column.maintenances">
            <div
              class="card w-full mb-2 grabbable item"
              v-if="can('Maintenances/Create') || (maintenance.user_id == user.id)"
              :key="maintenance.id"
            >
              <div
                class="flex-row d-flex justify-content-between"
                :style="{
                    'color': 'white',
                    'background-color': (maintenance.status == 'High') ? '#c0392b' : (maintenance.status == 'Medium') ? '#e67e22' : '#7f8c8d',
                  }"
              >
                <p class="my-auto p-2 text-truncate">{{ maintenance?.property?.name ?? 'Unknown' }}</p>

                <div class="d-flex flex-row">
                  <b-button class="btn-border" @click.prevent="edit(maintenance.id)" v-b-popover.top.hover="`Edit`"><fa-icon icon="pen" /></b-button>
                  <b-button class="btn-border" @click.prevent="view(maintenance.id)" v-b-popover.top.hover="`View`"><fa-icon icon="eye" /></b-button>
                </div>
              </div>
              <p class="p-2 mb-0">{{ maintenance.summary }}</p>
              <div
                class="card p-2"
                style="border-left: 0px; border-right: 0px; border-bottom: 0px;"
              >
                <p class="mb-0">Assigned To: {{ maintenance?.maintenance_contractor?.company_name ?? maintenance?.user?.name ?? 'Unknown' }}</p>
                <p class="mb-0">Next Visit: {{ getNextVisit(maintenance) ? moment(getNextVisit(maintenance).visit_at).format('DD/MM/YYYY') : 'None' }}</p>
              </div>
            </div>
          </template>
        </draggable>
      </b-card>
    </div>
    <div v-else>
      <b-table sortable responsive outlined :items="formattedMaintenance" :fields="['Location/Property', 'summary', 'stage', 'actions']">
        <template #cell(date)="row">
          {{ moment(row.item.date).format('DD/MM/YYYY @ HH:mm') }}
        </template>

        <template #cell(actions)="row">
          <div class="align-self-center d-flex flex-md-row flex-column">
            <b-button class="mb-1 mb-md-0 ml-md-1" :to="{ name: 'maintenance.details', params: { maintenanceId: row.item.id } }" variant="primary" v-b-popover.top.hover="`View extra details`"><fa-icon icon="arrow-right" /></b-button>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import StickyHeader from "@/components/StickyHeader.vue";
import draggable from 'vuedraggable'
import EditMaintenance from './Modals/EditMaintenance.vue'
import map from "lodash/map";

export default {
  components: { StickyHeader, draggable, EditMaintenance },
  computed: {
    ...mapState('maintenance', ['data', 'loading']),
    ...mapState('auth', ['user']),
    dragOptions() {
      return {
        animation: 0,
        group: "maintenance_jobs",
        ghostClass: "ghost"
      };
    }
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.load();
  },
  data() {
    return {
      lists: [],
      selectedMaintenance: {},
      formattedMaintenance: [],
      tableData: []
    }
  },
  methods: {
    ...mapActions('maintenance', ['fetch', 'fetchTable', 'updateOrCreate']),
    moment,
    async load() {
      if(this.can('Maintenances/Create')) {
        await this.fetch();
      } else {
        this.tableData = await this.fetchTable();
      }
    },
    can(perm) {
      let pass = false
      this.user.permissions.forEach((permission) => {
        if (permission.name == perm) pass = true;
      });

      return pass;
    },
    edit(id) {
      let column;
      let card;
      for (column of this.data) {
        for (card of column.maintenances) {
          if (card.id === id) {
            this.selectedMaintenance = card;
          }
        }
      }

      if (!(this.$route.name === 'maintenance.update' && this.$route.params.maintenanceId === id)) {
        this.$router.push({ name: 'maintenance.update', params: { maintenanceId: id } });
      }
    },
    view(id) {
      this.$router.push({ name: 'maintenance.details', params: { maintenanceId: id } });
    },
    onChange(element, column) {
      const item = element?.added?.element;

      if (!item) return;

      this.updateOrCreate({
        ...item,
        maintenance_stage_id: column.id
      });
    },
    getNextVisit(maintenance) {
      if(!(maintenance.visits) || !(maintenance.visits.length > 0)){
        return false;
      }

      // return the next visit up coming visit that has not already happened
      return maintenance.visits.find(visit => moment(visit.visit_at).isAfter(moment()));

    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    tableData(data) {
      this.formattedMaintenance = map(data, function(maintenance) {
        return {
          id: maintenance.id,
          'Location/Property': maintenance?.property?.name ?? 'Unknown',
          summary: maintenance?.summary ?? '',
          stage: maintenance.stage.name
        };
      })
    }
  }
}
</script>

<style>
@media (min-width: 768px) {
  .dayview-width {
    overflow-x: auto;
    width: calc(100vw - 319px);
  }
}
@media (max-width: 768px) {
  .dayview-width {
    overflow-x: auto;
  }
}

.btn-border {
  background-color: transparent;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-color: white;
}

.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable.sortable-chosen {
  cursor: grabbing !important;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
